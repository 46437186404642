import Flex from 'components/Flex/Flex';
import Typography from 'components/Typography/Typography';
import { BREAKPOINTS } from 'constants/breakpoints';
import { IFeaturette } from 'types/ContentfulTypes';
import { DEFAULT_SIZE_KEY, ImageSizes } from 'types/Image';
import { cn } from 'utils/cn';
import ContentfulFieldRenderer from '../../ContentfulFieldRenderer/ContentfulFieldRenderer';
import ContentfulModelMapper from '../../ContentfulModelMapper/ContentfulModelMapper';
import ContentfulCallToAction from '../ContentfulCallToAction/ContentfulCallToAction';
import { ContentfulFeaturetteWrapper, StyledRichTextContentWrapper } from './ContentfulFeaturette.styled';

interface ContentfulFeaturetteProps {
  className?: string;
  content: IFeaturette;
  sizes: ImageSizes;
}

const ContentfulFeaturette = ({
  className,
  content,
  sizes = {
    [BREAKPOINTS.MD]: 800,
    [BREAKPOINTS.SM]: 743,
    [DEFAULT_SIZE_KEY]: 300,
  },
}: ContentfulFeaturetteProps) => {
  const { callToActions, image, productCarousel, richText, title, type } = content.fields;

  if (!((title || richText) && image)) {
    return null;
  }

  const sectionStyleMapper = {
    firstSection: {
      left: 'md:col-span-5',
      right: 'md:col-span-5 md:col-start-8',
      vertical: 'col-span-full',
    },
    secondSection: {
      left: 'md:col-span-6 md:col-start-7',
      right: 'md:col-span-6 md:row-start-1',
      vertical: 'col-span-full',
    },
  };

  const imageModel = image?.sys?.contentType?.sys?.id;

  return (
    <ContentfulFeaturetteWrapper
      className={cn('grid grid-cols-1 gap-4 md:grid-cols-12', className)}
      id={content.sys?.id}
      imageModel={imageModel}
    >
      <ContentfulModelMapper
        additionalProps={{ nested: true, sizes }}
        className={`media-wrapper ${sectionStyleMapper.firstSection[type?.toLowerCase() as Lowercase<typeof type>]}`}
        content={image}
      />

      {(title || richText || !!callToActions?.length) && (
        <div
          className={cn(
            'flex flex-col gap-3',
            sectionStyleMapper.secondSection[type?.toLowerCase() as Lowercase<typeof type>],
          )}
        >
          {title && (
            <Typography marginBottom={0} type="h2">
              {title}
            </Typography>
          )}
          {richText && (
            <StyledRichTextContentWrapper>
              <ContentfulFieldRenderer className="richtext-content" richTextContent={richText} />
            </StyledRichTextContentWrapper>
          )}
          {!!callToActions?.length && (
            <Flex flexWrap="wrap" gap={50}>
              {callToActions?.map((callToAction) => (
                <ContentfulCallToAction key={callToAction.sys.id} content={callToAction} />
              ))}
            </Flex>
          )}
          {productCarousel && (
            <ContentfulModelMapper
              additionalProps={{
                cardSize: 'small',
                slidesObject: { lg: 3, md: 2, sm: 1.5, xl: 3, xs: 1.5, xxl: 3 },
              }}
              content={productCarousel}
            />
          )}
        </div>
      )}
    </ContentfulFeaturetteWrapper>
  );
};

export default ContentfulFeaturette;
