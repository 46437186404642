import { BREAKPOINTS } from 'constants/breakpoints';
import styled, { css } from 'styled-components';
import { breakpointUp, spacer } from 'theme/functions';
import { CONTENTFUL_TYPES, ContentfulType } from '../types';

interface ContentfulFeaturetteWrapperProps {
  imageModel?: ContentfulType;
}

export const ContentfulFeaturetteWrapper = styled.div<ContentfulFeaturetteWrapperProps>`
  ${({ imageModel }) =>
    imageModel === CONTENTFUL_TYPES.VIDEO &&
    css`
      .player-wrapper {
        height: 100%;
      }
    `}

  .media-wrapper {
    min-height: 300px;

    ${breakpointUp(
      BREAKPOINTS.MD,
      css`
        min-height: 400px;
      `,
    )};
  }
`;

export const StyledRichTextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacer(100)};
  .richtext-content {
    margin-bottom: 0px;
  }
`;
